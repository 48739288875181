<template>
  <div v-if="showModal" class="modal">
    <div class="modal__overlay" @click="onHideModal"></div>
    <div class="modal__content">
      <slot name="modal-title" :closeModal="onHideModal">
        <div v-if="title" class="modal__title">
          {{ title }}
        </div>
      </slot>
      <slot :payload="payloadData" :closeModal="onHideModal" />
      <slot name="close-button" :closeModal="onHideModal">
        <base-icon
          v-if="!hideCloseBtn"
          class="modal__close-btn"
          width="24"
          height="24"
          screen-size-dependency
          :path="'Close.svg'"
          @click="onHideModal"
        />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, toRef, watch } from "vue";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";

const emits = defineEmits(["update:payload", "update:show"]);
const props = defineProps({
  title: String,
  hideCloseBtn: Boolean,
  show: { type: Boolean, default: false },
});

const showModal = ref(props.show);
const payloadData = ref();

const noflow = () => {
  document.body.style.overflow = "hidden";
  document.body.style.userSelect = "none";
};

const haveflow = () => {
  document.body.style.overflow = "auto";
  document.body.style.userSelect = "auto";
};

const onShowModal = payload => {
  showModal.value = true;
  payloadData.value = payload;
  noflow();
  emits("update:show", showModal.value);
};

const onHideModal = () => {
  haveflow();
  showModal.value = false;
};

watch(payloadData, () => {
  emits("update:payload", payloadData.value);
});

watch(toRef(props, "show"), newVal => {
  showModal.value = newVal;
});

onMounted(async () => {
  emitter.on(EVENTS.SHOW_MODAL, onShowModal);
});

onUnmounted(() => {
  emitter.off(EVENTS.SHOW_MODAL, onShowModal);
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/breakPoints";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background: rgba(15, 23, 42, 0.2);
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    padding: var(--sp-8);
  }

  &__close-btn {
    position: absolute;
    top: -24px;
    right: -24px;
    cursor: pointer;
  }
}

@include mobile-only {
  .modal {
    top: 48px;

    &__overlay {
      display: none;
    }

    &__content {
      position: initial;
      transform: none;
      height: 100%;
      padding: 0;
    }
  }
}
</style>
