<template>
  <div>
    <div v-if="isMobile" class="shift-table __mobile">
      <div v-for="(row, index) in rows" :key="index">
        <mobile-schedule-view :row="row" @openSchedule="openSchedule" />
      </div>
    </div>
    <table v-else class="shift-table __desktop">
      <thead>
        <tr>
          <th class=" shift-table__cell __head" v-for="item in headers" :key="item">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, index) in rows">
          <desktop-schedule-view :key="index" :row="row" @openSchedule="openSchedule" />
        </template>
      </tbody>
    </table>
    <schedule-modal />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vue2-helpers/vuex";
import { useRouter } from "vue2-helpers/vue-router";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";
import MobileScheduleView from "../MobileScheduleView";
import DesktopScheduleView from "../DesktopScheduleView";
import ScheduleModal from "../ScheduleModal";

const headers = ["Дата", "Локация", "Время", "Часов", "Тег", "Link"];
const props = defineProps({
  rows: { type: Array },
});

const store = useStore();
const router = useRouter();
const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);

const noflow = () => {
  document.body.style.overflow = "hidden";
  document.body.style.userSelect = "none";
};

const openSchedule = payload => {
  emitter.emit(EVENTS.SHOW_MODAL, payload);
  noflow();
  router.push({ name: "personal-chesstable-modal" });
};
</script>

<style lang="scss" scoped>
@import "@/styles/ui/fontSize";
@import "@/styles/ui/breakPoints";
@import "@/styles/helpers";

.shift-table {
  $borderSize: 2px;
  $borderColor: var(--color-neutral-100);

  &.__desktop {
    display: grid;
    grid-template-columns:
      minmax(95px, 13fr)
      minmax(50px, 47fr)
      minmax(132px, 14fr)
      minmax(50px, 8fr)
      minmax(50px, 9fr)
      minmax(50px, 9fr);
    max-width: 1149px;
    min-width: 100%;
    border-collapse: collapse;
    @include with-border(true, $borderSize, $borderColor, "bottom");
  }

  &.__mobile {
    display: none;
  }

  &__cell {
    display: flex;
    align-items: center;
    padding: var(--sp-3) var(--sp-6);
    padding-right: unset;

    @include with-border(false, $borderSize, $borderColor, "bottom");
    @include add-font-face("body-1");
    @include text-truncate;

    &:first-child {
      padding-left: var(--sp-11);

      @media (max-width: 1178px) {
        padding-left: var(--sp-6);
      }
    }

    &:last-child {
      padding-right: var(--sp-4);
    }

    &.__head {
      $filterHeight: 66px;
      $filterPadding: calc(var(--sp-6) + var(--sp-9));

      position: sticky;
      top: calc($filterHeight + $filterPadding);
      background: var(--color-neutral-50);
      text-align: left;
      color: var(--color-neutral-500);

      @include add-font-face("body-2");

      &:first-child {
        border-radius: 2px 0 0 0;
      }

      &:last-child {
        border-radius: 0 2px 0 0;
      }
    }

    &.__column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__coworkers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--sp-1);
  }

  &__coworker {
    @include add-font-face("body-3", var(--color-neutral-400));

    &:not(:last-child) {
      &:after {
        content: "•";
        display: inline-flex;
        padding: 0 var(--sp-1);
        color: var(--color-neutral-900);
      }
    }
  }

  thead,
  tbody,
  tr {
    display: contents;
  }
}

@include mobile-only {
  .shift-table {
    &.__desktop {
      display: none;
    }

    &.__mobile {
      display: flex;
      flex-direction: column;
      gap: var(--sp-3);
    }

    &__cell {
      padding: var(--sp-3) var(--sp-6);
      padding-right: unset;

      @include add-font-face("body-3");

      &:first-child {
        padding-left: var(--sp-8);
      }

      &:last-child {
        padding-right: var(--sp-4);
      }
    }
  }
}
</style>
