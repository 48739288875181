<template>
  <modal
    hide-close-btn
    :show="showModal"
    @update:payload="handlePayloadUpdate"
    @update:show="v => (showModal = v)"
    #default="{ payload, closeModal }"
  >
    <div class="schedule-modal">
      <div class="schedule-modal__header">
        <div class="schedule-modal__title">
          <base-icon
            v-if="isMobile"
            class="schedule-modal__close-btn mr-6"
            width="16"
            height="16"
            screen-size-dependency
            path="arrow-left.svg"
            @click="onClose(closeModal)"
          />
          <span>Расписание</span>
        </div>
        <base-icon-button
          v-if="!isMobile"
          class="schedule-modal__close-btn"
          width="16"
          height="16"
          icon-path="Close.svg"
          @click="onClose(closeModal)"
        />
      </div>
      <div class="schedule-modal__info">
        <div class="schedule-modal__date">
          <span class="color-neutral-600">{{ formattedDate(payload?.currentDate).dayOfWeek }}</span>
          <span class="ml-ex-6">{{ formattedDate(payload?.currentDate).dateFormatted }}</span>
        </div>
        <div class="schedule-modal__point ml-6">{{ payload?.currentPoint.name }}</div>
      </div>
      <div class="schedule-modal__list" v-if="payload">
        <list-group-item v-for="(item, index) in turns" :item="item" :key="index" class="schedule-modal__item" />
      </div>
      <loader v-else />
    </div>
  </modal>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vue2-helpers/vuex";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { request } from "@/components-js/requestSrv";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import { formattedDate } from "../../helpers";
import Modal from "@/components/Modal";
import ListGroupItem from "@/components/ListGroup/components/ListGroupItem";
import Loader from "@/components/Loader.vue";
import BaseIconButton from "@/components/BaseIconButton";
import BaseIcon from "@/components/BaseIcon";

const localPayload = ref(null);
const turns = ref([]);
const showModal = ref(false);

const store = useStore();
const route = useRoute();
const router = useRouter();
const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);

const getTurns = async () => {
  turns.value = await request("/api/turn/list_all_for_date", "POST", {
    date: dateFormatJS(localPayload.value?.currentDate),
  });
  turns.value = turns.value.filter(item => item.id_point === localPayload.value?.currentPoint.id);
  return turns.value;
};

function handlePayloadUpdate(newPayload) {
  localPayload.value = newPayload;
  getTurns();
}

const noflow = () => {
  document.body.style.overflow = "hidden";
  document.body.style.userSelect = "none";
};

const haveflow = () => {
  document.body.style.overflow = "auto";
  document.body.style.userSelect = "auto";
};

watch(
  localPayload,
  async newPayload => {
    if (newPayload) {
      await getTurns();
    }
  },
  { deep: true }
);

const onClose = cb => {
  showModal.value = false;
  haveflow();
  router.push({ name: "personal" });
  cb();
};

const handlePopState = () => {
  haveflow();
  showModal.value = false;
};

onMounted(() => {
  window.addEventListener("popstate", handlePopState);
  if (showModal.value === false && route.name === "personal-chesstable-modal") {
    router.push({ name: "personal" });
  }
});

onUnmounted(() => {
  window.removeEventListener("popstate", handlePopState);
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";
@import "@/styles/ui/breakPoints";

.schedule-modal {
  max-height: 90vh;
  width: 700px;
  max-width: 100%;
  padding: var(--sp-8);
  border-radius: 5px;
  background: white;

  @include add-font-face("body-1");

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    @include add-font-face("h4", false, true);
  }

  &__item,
  &__user,
  &__info {
    display: flex;
  }

  &__close-btn {
    cursor: pointer;
  }

  &__info {
    margin-top: var(--sp-3);
  }

  &__item {
    margin: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--sp-4);
    margin-top: var(--sp-3);
  }
}

@include mobile-only {
  .schedule-modal {
    max-height: 100vh;
    width: 100%;
    padding: var(--sp-4);
    padding-top: var(--sp-6);
    overflow: auto;
  }
}
</style>
